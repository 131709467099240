// ---------------------------------------------------------------------
//  Modal Styles
// ---------------------------------------------------------------------

body.modal-is-active {
    overflow: hidden;
}

.modal {
    width: 100%;
    height: 100%;
    background: #F3E8E6;
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s;

    &.is-active {
        opacity: 1;
        pointer-events: initial;
    }

    &.drop-back {
        z-index: 98;
    }

    .modal-inner {
        width: 100%;
        min-height: 100%;
        overflow: hidden;
        position: relative;

        > * {
            overflow: hidden;
        }


        @include start-at($medium) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    nav {
        @include stop-at($medium) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 60px;
            height: 60px;
            //background: red;
            z-index: 2000;


            @include start-at($medium) {
                position: absolute;

            }

            &:after {
                content: '';
                display: block;
                width: 40px;
                height: 25px;
                background: url('../images/svgs/arrow-right-black.svg') center / cover no-repeat;
                transition: transform $trans;
            }

            &.next-meal {
                bottom: 0;
                right: 0;

                @include start-at($large) {
                    bottom: calc(50% - 30px);
                    right: 0;
                }

                @include start-at(1400px) {
                    right: 5%;
                }

                &:hover {
                    &:after {
                        transform: translate3d(4px, 0, 0);
                    }
                }
            }

            &.prev-meal {
                bottom: 0;
                left: 0;

                @include start-at($large) {
                    bottom: calc(50% - 30px);
                    left: 0;
                }

                @include start-at(1400px) {
                    left: 5%;
                }

                &:after {
                    transform: translate3d(0, 0, 0) rotate(180deg);
                }

                &:hover {
                    &:after {
                        transform: rotate(180deg) translate3d(4px, 0, 0);
                    }
                }
            }
        }



        @include start-at($medium) {

        }
    }
}

.modal-content {
    width: 90%;
    max-width: 1100px;
    padding: 60px 0;
    //background: red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    overflow: scroll;
    justify-content: flex-end;
    position: relative;

    @include start-at($medium) {
        //height: 100%;
        //padding: 60px 0;
    }
}


// ----------------------------------------
// Close
// ----------------------------------------


.modal__close {
    position: absolute;
    z-index: 13;
    display: block;
    transition: opacity 0.4;
    border-radius: 50%;
    top: 10px;
	right: 10px;
    width: 50px;
    height: 50px;

    &:hover {
        &:before,
        &:after {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
    }

    &:before,
    &:after {
        content: '';
        display: block;
        width: 30px;
        height: 1px;
        background: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transition: transform 0.2s linear;
    }

    &:before {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
    &:after {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }

    &:hover {
        cursor: pointer;
    }
}

// ---------------------------------------------------------------------
//  Global Header Styles
// ---------------------------------------------------------------------

// ----------------------------------------
// Site header
// ----------------------------------------

.site-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 50px 0 10px;
    transition: all $trans;

    @media(max-height: 720px) {
        padding: 20px 0 10px;
    }
}


// ----------------------------------------
// Logo
// ----------------------------------------

#site-logo,
.site-logo {
    width: 146px;
    margin: 0 auto;

    a {
        display: block;
        width: 146px;
        height: 42px;
        margin: 0 auto;
    }
}

.site-logo {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;

    @media(max-height: 720px) {
        top: 20px;
    }
}



// ----------------------------------------
// Interior Header
// ----------------------------------------

.interior-header {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    color: $white;
    width: 100%;
    padding-top: 30px;
    transition: padding-top $trans;

    @media(min-height: 720px) {
        padding-top: 60px;
    }

    h1 {
        font-family: $sans-serif;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;
        letter-spacing: 3px;
    }
}

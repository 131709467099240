// ---------------------------------------------------------------------
//  Utility Classes
// ---------------------------------------------------------------------

.has-accent:before {
    content: "";
    width: 30px;
    height: 5px;
    display: block;
    background: $brand;
    margin: 30px 0;
}

.has-accent-after:after {
    content: "";
    width: 70px;
    height: 4px;
    display: block;
    background: $white;
    margin: 30px auto;
}

.accent-left{
    display: flex;
    align-items: flex-start;

    &:before {
        content: "";
        width: 15px;
        min-width: 15px;
        height: 3px;
        display: block;
        background: $brand;
        margin: 10px 10px 10px 0;
    }

    @include start-at($medium) {


        &:before {
            content: "";
            width: 30px;
            min-width: 30px;
            height: 5px;
            display: block;
            background: $brand;
            margin: 10px 60px 10px 0;
        }
    }
}

.rel-pos {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

// ----------------------------------------
// Text
// ----------------------------------------

.text-center {
    text-align: center;

    p, img, .text-thin {
        margin-left: auto;
        margin-right: auto;
    }
}

.text-thin {
    max-width: 560px;
}

.text-brand {
    color: $brand;
}

.text-white {
    color: $white;
}

.text-light {
    color: $gray;
}

.text-gray-alt {
    color: $gray-alt;
}

.text-light-blue {
    color: $light-blue;
}

.text-brand-dark {
    color: $brand-dark;
}
.text-black {
    color: $gray-dark;
}

.text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.52);
}

.text-brown {
    color: $brown;
}

.text-italic {
    font-style: italic;
}

.text-bold {
    font-weight: bold;
}

.tw-460 {
    max-width: 460px;
}

.tw-500 {
    max-width: 550px;
}

.tw-730 {
    max-width: 740px;
}

.tw-820 {
    max-width: 720px;
}

.tw-830 {
    max-width: 830px;
}

.tw-920 {
    max-width: 920px;
}

@include stop-at($large) {
    .is-hidden-md {
        display: none;
    }
}


// ----------------------------------------
// Backgrounds
// ----------------------------------------

.bg-brand {
    background: $brand;
}

.bg-white {
    background: $white;
}

.bg-light {
    background: $gray-light;
}

.bg-cream {
    background: $cream;
}

.bg-brown-light {
    background: $brown-light;
}

.bg-pink {
    background: #F3E8E6;
}

.bg-brown {
    background: $brown;
}

.bg-brown-dark {
    background: #BC9E7B;
}

.bg-cream-dark {
    background: $cream-dark;
}
.bg-green {
    background: $green;
}


.sr-only,
.screen-reader-text {
    @include visually-hidden;
}

@include stop-at($small) {
    .hide-before-sm {
        display: none;
    }
}


.platform-intro {
    display: flex;
    align-items: center;

    @include start-at($medium) {
        align-items: baseline;
    }

    .h6 {
        font-size: 14px;
        padding-right: 4px;
        margin: 0;

        @include start-at($large) {
            font-size: 16px;
        }
    }

    .p-alt {
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 14px;
        padding-left: 8px;
        border-left: 1px solid $black;
        margin: 0;

        @include start-at($large) {
            font-size: 16px;
        }
    }
}

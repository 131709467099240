// ---------------------------------------------------------------------
//  Drawer Styles
// ---------------------------------------------------------------------


// ----------------------------------------
// Home Drawer
// ----------------------------------------

body.open-drawer {

    .drawer {
        opacity: 1;
        pointer-events: inherit;
    }
}

.drawer {
    width: 100%;
    align-items: center;
    z-index: 9;
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    //display: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;

    @media(min-height: 530px) {
        display: flex;
        align-items: center;
        justify-content: center;
        //display: none;
    }

    .drawer__content {
        max-width: 720px;
        padding: 90px $wrapper-padding;
        margin: 0 auto;

        @media(max-height: 720px) {
            padding: 110px $wrapper-padding 20px;

            .h1-alt {
                font-size: 36px;
            }
            .p-alt {
                font-size: 16px;
            }

            .has-accent:before {
                margin: 15px 0;
            }
        }

        @media(max-width: 340px) {
            .p-alt {
                font-size: 14px;
            }
        }
        @media(min-width: 340px) and (max-width: 600px) {
            .p-alt {
                font-size: 16px;
            }
        }

        @include start-at($medium) {
            padding: 90px 0;
        }
    }

    .hero-lg__image {
        position: fixed;
    }
}

.drawer__img {
    background: $gray;

    img, video {
        opacity: 0.33;
        mix-blend-mode: multiply;
    }

    img {
        display: none;
    }

    .isTouch & {
        img {
            display: block;
        }
    }
}


// ----------------------------------------
// Drawer Trigger
// ----------------------------------------

.drawer-trigger {
    width: 44px;
    height: 44px;
    background: $brand;
    display: block;
    position: fixed;
    top: 0;
    right: 5%;
    z-index: 10;
    transition: opacity $trans;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        background: $white;
        transform-origin: center;
        transition: transform $trans;
    }
    &:before {
        width: 24px;
        height: 2px;
        top: calc(50% - 1px);
        left: calc(50% - 12px);
    }
    &:after {
        width: 2px;
        height: 24px;
        top: calc(50% - 12px);
        left: calc(50% - 1px);
    }

    body.open-drawer &,
    &:hover {
        opacity: 0.8;
        &:before,
        &:after {
            transform: rotate(45deg);
        }
    }


    @include stop-at($medium) {
        top: auto;
        bottom: 0;
        right: 0;
    }
}

// .drawer-is-active {
//     .drawer-trigger {
//         opacity: 0;
//         pointer-events: none;
//     }
// }



// ----------------------------------------
// Interior Drawers
// ----------------------------------------

[data-drawer-target] {
    display: block;
    width: 41px;
    height: 41px;
    position: relative;
    background: $brand;
    border-radius: 50%;
    margin-top: 30px;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        background: $white;
        transform-origin: center;
        transition: transform $trans;
        transform: translate3d(0, 0, 0);
    }
    &:before {
        width: 22px;
        height: 3px;
        top: 19px;
        left: 10px;

    }
    &:after {
        width: 3px;
        height: 22px;
        top: 10px;
        left: 19px;
    }

    &.is-active,
    &:hover {
        opacity: 0.8;

        &:after {
            transform: translate3d(1px, 0, 0) rotate(90deg);
        }
    }
}

.interior-drawer {
    display: none;
}

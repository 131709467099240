// ---------------------------------------------------------------------
//  Article Styles
// ---------------------------------------------------------------------



// ----------------------------------------
// Split Content
// ----------------------------------------

.split-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 70px 0;
    max-width: 1440px;
    margin: 0 auto;
}

.split {
    width: 100%;
    max-width: 1030px;

    @include stop-at($large) {
        padding: 0 $wrapper-padding;
    }

    // @include stop-at($large) {
    //     padding: 0 30px;
    // }

    p {
        max-width: 622px;
    }
}


.split-content {
    width: 100%;

    @include start-at($medium) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
            width: 50%;

        }
    }
}

.split-content__content {
    margin-top: 24px;

    @include start-at($medium) {
        border-right: 5px solid #D8D8D8;
        padding-right: 80px;
        margin-top: 24px;
        margin-bottom: 24px;

    }
}

.split-content__media {
    display: block;
    margin: 40px 0;

    @include start-at($medium) {
        padding: 60px 20px 60px 40px;

        width: 60%;
    }
}



// ----------------------------------------
// Split Flip
// ----------------------------------------

.split-wrap.split-wrap--left {
    justify-content: center;

    .split {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        p {
            max-width: 680px;
        }
    }

    .split-content {

    }

    .split-content__content {

        @include start-at($medium) {
            border-right: none;
            padding-right: 0;
            border-left: 5px solid #D8D8D8;
            padding-left: 80px;
        }
    }

    .split-content__media {
        display: block;

        figure {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            img {
                display: block;
                max-width: none;
                margin: 0;
            }
        }

        @include start-at($medium) {
            padding: 60px 90px 60px 0;
        }
    }
}



// ----------------------------------------
// Blockquote
// ----------------------------------------

blockquote {
    font-family: $serif;
    font-size: 30px;
    line-height: 1.2;
    color: #3AC7D6;
    letter-spacing: -0.9px;
    max-width: 937px;
    width: $wrapper-percentage;
    margin: 50px auto;
    transition: transform 1s ease, opacity 1.4s ease;
    transform: translate3d(-4%, 0, 0);

    opacity: 0;

    &.in-view {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    @include start-at(1000px) {
        font-size: 50px;
        transform: translate3d(0, 0, 0);

        &.in-view {
            transform: translate3d(-50px, 0, 0);
        }
    }
}

// ---------------------------------------------------------------------
//  SCSS Settings
// ---------------------------------------------------------------------


// ----------------------------------------
// Fonts
// ----------------------------------------

// Font weights: 400 | 700
$sans-serif: "ColfaxWeb", mono, sans-serif;

// Normal/ Italic || 400 | 700
$display: "Mercury Display A", "Mercury Display B", Georgia, serif;

$serif: "Mercury Text G1 A", "Mercury Text G1 B", Georgia, serif;

// ----------------------------------------
// Font Size/ Line Height
// ----------------------------------------
$main-font: $sans-serif;
$base-font-size: 1.5rem;
$base-line-height: 1.6;


// ----------------------------------------
// Colors
// ----------------------------------------

$brand: #3AC7D6; // Teal
$brand-dark: #117C81;
$brand-light: #A1D9DF;

$brand-alt: #E7FD3C;

$gray-dark: #2A2A2A;
$gray: #7F7F7F;
$gray-alt: #9B9B9B;
$gray-light: #F3F3F3;

$brown: #E0D3CC;
$light-blue: #ADE0E6;


$brand-bright: #FE6340;

$cream: #DEDDCA;
$cream-dark: #F8ECDC;
$green: #475A51;
$brown-light: #E0D3CB;


$black: #030303;


$white: #ffffff;


$gray: #666;

$error: #FF3333;


// ----------------------------------------
// Breakpoints
// ----------------------------------------

$xlarge: 1440px;
$large: 1144px;
$medium: 800px;
$small: 600px;

@mixin start-at($device-width) {
  @media (min-width: $device-width) {
    @content;
  }
}

@mixin stop-at($device-width) {
  @media (max-width: $device-width - 1) {
    @content;
  }
}


// ----------------------------------------
// Global Variables
// ----------------------------------------

$header-breakpoint: $medium;

$transition-speed: 0.4s;
$transition: $transition-speed ease;
// cuz I no type good
$trans: $transition-speed ease;

$wrapper-percentage: 84%;
$wrapper-width: 1260px;
$wrapper-padding: (100% - $wrapper-percentage) / 2;

// ---------------------------------------------------------------------
//  Global Footer Styles
// ---------------------------------------------------------------------

.site-footer {
    overflow: hidden;
    background: $gray-dark;
    color: $white;
    display: flex;
    align-items: flex-end;
    padding: 150px 0 130px;
    min-height: 100vh;

    .container {
        display: flex;
        justify-content: flex-end;

        > * {
            max-width: 100%;
            width: 680px;
        }

        .p-alt {
            font-family: $sans-serif;
            font-weight: 400;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 30px;
        }
    }

}


// ----------------------------------------
//
// ----------------------------------------

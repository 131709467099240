// ---------------------------------------------------------------------
//  Media Styles
// ---------------------------------------------------------------------

.img-full {
    width: 100%;
}

.img-cover {
    object-fit: cover;
    width:100%;
    height:100%;
}

.bg-image {
    @include bg-image;
}

@include stop-at($medium) {
    .bg-min-h-sm {
        position: relative;
    }
}

video {
    width: 100%;

    .isTouch & {
        display: none;
    }
}




// ----------------------------------------
//  Media Block
// ----------------------------------------

.media-block {
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-dark;


    @include stop-at($large) {
        flex-wrap: wrap;
        height: auto;
        min-height: 100vh;
        padding: 0 0 60px;

        &.media-block--video {
            padding: 0 0 0;
        }
    }

    .media-block__content {
        padding: 0 $wrapper-padding;
    }

    @include start-at($large) {
        .media-block__content {
            width: 50%;
            margin-left: auto;
            padding: 0;
        }
    }
}

.media-block__media {
    flex-wrap: wrap;

    @include start-at($large) {
        flex-wrap: nowrap;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -1;

        img, video {
            object-fit: cover;
            width: 100%;
            height: 101%;
        }
    }


    &.media-block__media-video {
        flex-wrap: nowrap;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -1;
        margin: 0;

        img, video {
            object-fit: cover;
            width: 100%;
            height: 101%;
        }
    }

    @include stop-at($large){
        order: -1;
        margin: 60px 0 20px;
    }
}

.media-block__media.media-block__media--ui,
.media-block---ui {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include start-at($large) {
        width: 50%;
    }

    img.ui-holder-img {
        object-fit: none;
        width: 100%;
        height: auto;
        max-width: 520px;
        position: relative;
        opacity: 0;
    }

    .ui-holder {
        position: relative;
    }

    .ui-cards img {
        width: auto;
        height: auto;
        position: absolute;
        width: calc(50% - 5px);
        max-width: 253px;

        &:first-child {
            top: 0;
            left: 0;
        }
        &:nth-child(2) {
            top: 0;
            right: 0;
        }
        &:nth-child(3) {
            bottom: 0;
            left: 0;
        }
        &:nth-child(4) {
            bottom: 0;
            right: 0;
        }
    }
}

.media-block---ui {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include start-at($large) {
        width: 100%;
    }

}

.has-screen {

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: black;
        opacity: 0.2;
    }

    &.has-screen--dark {
        &:after {
            opacity: 0.4;
        }
    }
}


// ----------------------------------------
// Bitmap Image
// ----------------------------------------

/// ALL will sit above execpt last Liora Image

@include stop-at($large){
    .order-swap-sm,
    .section-full.order-swap-sm {
        display: flex;
        flex-wrap: wrap;
            padding: 0px 0 60px;

        .img-bitmap {
            order: -1;
            margin-bottom: 30px;
        }
    }
}

.img-bitmap {

    img,
    video {
        width: 100%;
        display: block;

        @include start-at($large) {
            position: absolute;
            height: 100%;
            width: auto;
            right: 0;
            top: 0;
            z-index: -1;
            opacity: 0;
            max-width: none;
        }
    }
}

@include start-at($large) {
    .img-bitmap--liora-1 {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 50%;
        left: 50%;
        top: 0;
        //background: red;

        img {
            // max-width: 50%;
            // height: auto;
            right: auto;
            left: 0;
            height: 90%;
            top: 5%;
        }
    }
}


// ----------------------------------------
//  Two Thirds
// ----------------------------------------

.two-thirds {
    width: 100%;

    @include start-at($medium) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100vh;
    }

    .h1 {
        letter-spacing: 0;
        text-align: left;
        line-height: 1;
    }
}

.two-thirds__img {
    width: 100%;
    position: relative;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @include start-at($medium) {
        height: 100vh;
        width: 370px;
        order: 2;
        min-width: 370px;
    }
}

.two-thirds__content {
    color: $white;
    padding: 40px $wrapper-padding;

    > * {
        max-width: 880px;
        margin: 0 auto;
    }

    @include start-at($medium) {
        display: flex;
        justify-content: center;
        padding: 80px $wrapper-padding;
        flex-grow: 1;
    }
}

// ----------------------------------------
// SVGs
// ----------------------------------------

svg {
	display: block;
}


// ----------------------------------------
// Media Object
// ----------------------------------------

.media-object {
    width: 100%;
    overflow: hidden;

    @include start-at($medium) {
        display: flex;
        align-items: center;

        &.media-object--equalWidth > * {
            width: 48%;

            img {
                width: 100%;
            }
        }

        &.media-object--flip .media-object__media {
            order: 2;
            margin: 0 0 0 24px;
        }
    }
}

.media-object__media {
    margin: 0 0 24px 0;

    img {
       display: block;
   }

   @include start-at($medium) {
       margin: 0 24px 0 0;
       flex-grow: 1;
       min-width: 200px;
   }
}

.media-object__bd {
    overflow: hidden;
}


.background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;

    @include start-at($large) {
        opacity: 1;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}


// ---------------------------------------------------------------------
//  Wrapper Styles (Horizontal Spacing)
// ---------------------------------------------------------------------

.container {
    max-width: 888px;
    width: $wrapper-percentage;
    margin-left: auto;
    margin-right: auto;

    @include start-at(1440px) {
        max-width: 1200px;
    }
}

.container-alt {
    width: $wrapper-percentage;
    max-width: 1061px;
    margin-left: auto;
    margin-right: auto;

    @include start-at(1440px) {
        max-width: 1400px;
    }
}

.container--lg {
    max-width: $wrapper-width;
}

.container--lg-alt {
    max-width: 1120px;
}


.container-pull {
    position: relative;
    z-index: 2;
    width: 100%;
    overflow: hidden;
    padding: 40px 0;
    margin: -60px auto 0;
    background: $white;

    @include start-at($small) {
        max-width: 1400px;
        width: $wrapper-percentage;
        padding: 70px 20px;
        margin: -90px auto 0;
    }
}

.container--x-thin {
    max-width: 515px;
}

.container--thin {
    max-width: 630px;
}

.container--md {
    max-width: 730px;
}

.container-full {
    width: 100%;
    overflow: hidden;
}

.wrapper-full--brand {
    background: $brand;
}

// ---------------------------------------------------------------------
//  Base Global Styles
// ---------------------------------------------------------------------

html,
body {
    font-size: 62.5%;
}

body {
    font-family: $main-font;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $gray-dark;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}

body#home {
    background: $gray-dark;
    opacity: 0;
    transition: opacity $trans, background $trans;

    &.is-loaded {
        background: $white;
        opacity: 1;
    }
}

main#content {
    min-height: 500px;
    background: $white;

}

.grid {
    display: flex;
    flex-wrap: wrap;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }

    &[href^="tel:"] {

        &:hover {
            cursor: default;
        }
    }
}

a[data-scroll-to],
[data-click-target] {
    cursor: pointer;
}

svg {
    display: block;
}

img {
    height: auto;
    max-width: 100%;
    display: block;
}

sup {
    vertical-align: super;
    font-size: smaller;
}

picture > * {
    width: 100%;
    height: auto;
}

q {
    hanging-punctuation: first;
}

@mixin selection {
    color: $white;
    background: $brand;
}

::selection {
    @include selection;
}
::-moz-selection {
    @include selection;
}

// ---------------------------------------------------------------------
//  Typography Styles
// ---------------------------------------------------------------------


.h1 {
    display: block;
    font-family: $display;
    font-weight: 700;
    font-weight: 800;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 8px;
    text-align: center;
    line-height: 1.333;

    @include start-at($medium) {
        font-size: 48px;
        letter-spacing: 9px;
    }
}

.h1-alt {
    font-family: $serif;
    font-weight: 400;
    font-size: 44px;
    color: #F6F6F6;
    letter-spacing: 0;
}


.h2 {
    display: block;
    font-family: $serif;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 0;

    @include stop-at($medium) {
        font-size: 24px;
    }
}

.h3 {
    display: block;
    font-family: $serif;
    font-size: 36px;
    line-height: 1.33;
    color: #9B9B9B;
    letter-spacing: 0;

    + p {
        margin-top: 20px;
    }

    @include stop-at($medium) {
        font-size: 20px;
    }
}


.h4 {
    font-family: $sans-serif;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 1.8;

    @include stop-at($medium) {
        font-size: 18px;
    }
}

.h5 {
    font-family: $sans-serif;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 1.5;

    @include stop-at($medium) {
        font-size: 18px;
    }
}

.h6 {
    font-family: $sans-serif;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
}



p,
.p {
    font-family: $serif;
    font-size: 17px;
    letter-spacing: -0.9px;
    line-height: 1.85;

    + p {
        margin-top: 25px;
    }

    + .h3 {
        margin-top: 50px;
    }

    + .btn {
        margin-top: 32px;
    }
}

.p--lg {

    @include start-at($medium) {
        font-size: 20px;
        line-height: 1.7;
    }
}

.p-lead {
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -.88px;
    line-height: 2;
    position: relative;

    b {
        font-family: $sans-serif;
        font-weight: 500;
        letter-spacing: 3px;
    }

    &:before {
        content: '';
        display: block;
        background: $brand;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 11px;
        left: -12px;
    }

    @include start-at($medium){
        font-size: 18px;
        line-height: 2.14;

        &:before {
            width: 13px;
            height: 13px;
            top: 13px;
            left: -25px;
        }
    }
}

.p-bold {
    font-family: $sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0;
}


.p-alt {
    font-family: $sans-serif;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.74px;
    line-height: 1.5;
}

@include start-at($medium){
    .p-alt--lg {
        font-size: 20px;
        line-height: 1.7;
    }
}

.p-sm {
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 1.5;
    font-weight: 400;
}


.text-content {

    .text-link {
        display: block;
        color: $brand;
        text-decoration: underline;
        transition: opacity 0.3s;

        &:hover {
            text-decoration: underline;
            opacity: 0.6;
        }
    }

    p + .text-link,
    .text-link + .text-link {
        margin-top: 15px;
    }
}


.tel {
    font-family: $sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #9B9B9B;
    letter-spacing: 1px;
}


// ----------------------------------------
// Call to action
// ----------------------------------------

q {
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
    }
    // &:after {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     transform: translate3d(100%, 0, 0);
    // }
}

// ----------------------------------------
// Call to action
// ----------------------------------------

.cta {
    position: relative;
    padding-left: 15px;
    margin-top: 50px;

    &:before {
        position: absolute;
        height: 100%;
        width: 4px;
        background: $brand-light;
        content: '';
        top: 0;
        display: block;
        left: 0;
        transform: scaleX(1);
        transition: opacity 0.4s, transform 0.4s;
    }

    &.in-view {

    }

    .tel {
        display: block;
    }

    @include start-at($medium) {
        margin-top: 90px;
        padding-left: 30px;
    }
}


.cta__hd {
    font-family: $sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include start-at($medium) {
        font-size: 14px;
    }
}


// ----------------------------------------
// DL
// ----------------------------------------

dt, dt, dd {
    // font-weight: 500;
    // font-size: 18px;
    color: $gray-alt;
}


.text-w-300 {
    font-weight: 300;
}
.text-w-400 {
    font-weight: 400;
}
.text-w-500 {
    font-weight: 500;
}
.text-w-700 {
    font-weight: 700;
}

.font-serif {
    font-family: $serif;
}
.font-ss {
    font-family: $sans-serif;
}

.text-green {
    color: $green;
}


// ----------------------------------------
// Fancy numbers
// ----------------------------------------

.fancy-number {
    display: inline-block;
    vertical-align: middle;
    transform: rotate(-90deg);
    transform-origin: center;
    //margin-left: 20px;
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 48px;
    color: #AFE0E5;
    letter-spacing: 0;
    position: relative;
    z-index: 1;


    // &:after {
    //     content: '';
    //     position: absolute;
    //     display: block;
    //     height: 112px;
    //     width: 4px;
    //     //background: #EAFB2D;
    //     top: 20px;
    //     left: 20px;
    //     z-index: -1;
    //     //transform: rotate(90deg);
    // }
}

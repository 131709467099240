// ---------------------------------------------------------------------
//  Link Styles
// ---------------------------------------------------------------------

.btn {
    display: inline-block;
    min-width: 193px;
    border: 3px solid $brand;
    color: $brand-dark;
    text-align: center;
    vertical-align: middle;
    padding: 10px 20px;

    // Font Styles
    font-family: $sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2.5px;

    transition: color $trans, background $trans;

    @include stop-at($large) {
        font-size: 14px;
    }

    &:hover {
        background: $brand;
        color: $white;
    }
}


// ----------------------------------------
// Strike Through
// ----------------------------------------

.underline-link {
    color: $brand;
    position: relative;
    padding-bottom:4px;

    &:before {
        position: absolute;
        width: 100%;
        height: 2px;
        background: $brand;
        content: '';
        bottom: 0;
        //margin-top: 4px;
        display: block;
        left: 0;

        transform: scaleX(0);
        transition: opacity 0.4s, transform 0.4s;
    }

    &:hover {
        color: $brand;

        &:before {
            transform: scaleX(1);
        }
    }
}

.strike-through {
    position: relative;

    &:before {
        position: absolute;
        width: 100%;
        height: 1px;
        background: rgba($brand-alt, 0.6);
        content: '';
        top: 50%;
        display: block;
        left: 0;
        transform: translate3d(0, -50%, 0);
        transform-origin: left;
        transition: opacity 0.3s, height 0.3s ease-in;
        transition-delay: 0.3s;

        height: 100%;

        @include start-at($medium) {
            height: 100%;
        }
    }

    &.in-view {
        &:before {
            height: 2px;

            @include start-at($medium) {
                height: 3px;
            }
        }
    }

    &:hover {

        &:before {
            height: 100%;
        }
    }
}


.link-lg {
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.2;
    color: #FFFFFF;
    letter-spacing: 0;

    @include start-at($medium) {
        font-size: 36px;
    }

    // @include start-at($large) {
    //     font-size: 60px;
    // }
}


.user-content {
    p a {
        color: $brand;
    }
}

.link-underline-lg {
    border-bottom: 11px solid rgba(#D8D8D8, .56);
    transition: border-color $trans;

    &:hover {
        border-color: rgba(#D8D8D8, .26)
    }
}



.highlight-links a,
a.highlight-links {
    display: inline-block;
    padding-bottom: -21px;
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        background: rgba(#D8D8D8, .56%);
        height: 11px;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

// ---------------------------------------------------------------------
//  Heros
// ---------------------------------------------------------------------


.hero-lg {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 180px $wrapper-padding 210px;
    position: relative;
    z-index: 1;
    min-height: 660px;

    @media(max-height: 720px) {
        align-items: center;
        padding: 100px $wrapper-padding
    }
}

.hero-lg__content {
    max-width: 820px;

    p {
        max-width: 810px;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
    }
}

.hero-lg__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    img, video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    img {
        z-index: -2;
    }
}


// ----------------------------------------
// Standard
// ----------------------------------------

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px $wrapper-padding;
    position: relative;
    z-index: 1;
    min-height: 100vh;
}

.hero__content {
    position: relative;
    z-index: 1;
}

// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebThin.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebThin.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebThin.woff') format('woff');
// 	font-weight: 100;
// 	font-style: normal;
// }
//
// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebThinItalic.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebThinItalic.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebThinItalic.woff') format('woff');
// 	font-weight: 100;
// 	font-style: italic;
// }

@font-face {
	font-family: 'ColfaxWeb';
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebLight.eot');
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebLight.eot?#iefix') format('embedded-opentype'),
			url('../fonts/ColfaxWeb_WOFF/ColfaxWebLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebLightItalic.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebLightItalic.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebLightItalic.woff') format('woff');
// 	font-weight: 300;
// 	font-style: italic;
// }

@font-face {
	font-family: 'ColfaxWeb';
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebRegular.eot');
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebRegular.eot?#iefix') format('embedded-opentype'),
			url('../fonts/ColfaxWeb_WOFF/ColfaxWebRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebRegularItalic.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebRegularItalic.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebRegularItalic.woff') format('woff');
// 	font-weight: 400;
// 	font-style: italic;
// }

@font-face {
	font-family: 'ColfaxWeb';
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebMedium.eot');
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebMedium.eot?#iefix') format('embedded-opentype'),
			url('../fonts/ColfaxWeb_WOFF/ColfaxWebMedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebMediumItalic.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebMediumItalic.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebMediumItalic.woff') format('woff');
// 	font-weight: 500;
// 	font-style: italic;
// }

@font-face {
	font-family: 'ColfaxWeb';
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBold.eot');
	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBold.eot?#iefix') format('embedded-opentype'),
			url('../fonts/ColfaxWeb_WOFF/ColfaxWebBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBoldItalic.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBoldItalic.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebBoldItalic.woff') format('woff');
// 	font-weight: 700;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBlack.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBlack.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebBlack.woff') format('woff');
// 	font-weight: 600;
// 	font-style: normal;
// }
//
// @font-face {
// 	font-family: 'ColfaxWeb';
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBlackItalic.eot');
// 	src: 	url('../fonts/ColfaxWeb_EOT/ColfaxWebBlackItalic.eot?#iefix') format('embedded-opentype'),
// 			url('../fonts/ColfaxWeb_WOFF/ColfaxWebBlackItalic.woff') format('woff');
// 	font-weight: 600;
// 	font-style: italic;
// }

// ---------------------------------------------------------------------
//  liora Styles
// ---------------------------------------------------------------------

$liora: #86876E;
$liora-alt: #5F6259;
$liora-dark: #5C6251;

$liora-yellow: #EBDC18;

.bg-liora {
    background: $liora;
}
.bg-liora-alt {
    background: $liora-alt;
}
.bg-liora-yellow {
    background: $liora-yellow;
}
.text-liora-dark {
    color: $liora-dark;
}
.bg-purple {
    background: #9317F3;
}

#liora {

    .hero {
        background: $liora;

        .hero__content {
            width: 100%;
            max-width: 1157px;

            @include start-at(1440px) {
                max-width: 1600px;
            }

            p {
                max-width: 1061px;

                + p {
                    margin-top: 0;
                }
            }
        }
    }

    [data-drawer-target] {
        background: $brand;
    }

    .h1 {
        letter-spacing: 0;
        text-align: left;
        line-height: 1.125;
    }

    .container-alt {
        max-width: 1157px;

        @include start-at(1440px) {
            max-width: 1600px;
        }
    }
}



.liora-h-rule {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;

    @include stop-at($medium) {
        right: -25px;
    }
}

.liora-logo {
    width: 515px;
    max-width: 80%;

}

.liora-rule {
    margin: 30px 0;

    @include start-at($medium) {
        margin: 45px 0;
    }

    @media (min-height: 820px){
        margin: 80px 0;
    }
}

main {
    overflow: hidden;
}

.bg-liora {
    overflow: visible;
    z-index: 2;
    width: 100%;

    .container {
        position: relative;
        z-index: 2;

        @include start-at($medium) {
            > * {
                width: 585px;
            }
        }
    }

    .liora-product {
        // max-width: 145px;
        // position: absolute;
        // right: 7%;
        // top: calc(100% - 160px);
        // z-index: 1;
        // order: 1;
        //

        @include stop-at($medium) {
            height: 320px;
            width: auto;
            transform-origin: center;
            transform: rotate(-90deg);
            order: -1;
        }

        // @include start-at($small) {
        //     max-width: 285px;
        //     top: calc(100% - 320px);
        // }
        //
        // @include start-at($medium) {
        //     right: 65%;
        //     max-width: 465px;
        //     top: auto;
        //     bottom: -70px;
        // }
        //

        @include start-at($medium) {
            left: -10%;
            right: auto;
            order: 2;
            max-width: 285px;
            bottom: -40px;
            position: absolute;
        }

        @include start-at($large) {
            left: 2%;
            //top: calc(100% - 320px);
            bottom: -80px;
            max-width: 465px;
        }

        @include start-at(1400px) {
            left: 7%;
        }

        @include start-at(1640px) {
            left: 7%;
            bottom: -140px;
            max-width: none;
            max-height: 930px;
        }
    }
}



.liora-product-wrap {
    display: flex;
    flex-wrap: wrap;
}


// ----------------------------------------
//
// ----------------------------------------

// ---------------------------------------------------------------------
//  Layout
// ---------------------------------------------------------------------

.section-full {
    min-height: 100vh;
    overflow: hidden;
    flex-wrap: wrap;
    //padding: 60px 0 0;

    @media (max-height: 500px) {
        min-height: 500px;
    }

    @include stop-at($large) {
        min-height: 568px;

        // .img-bitmap,
        // .bg-image {
        //     order: -1;
        //     margin-bottom: 40px;
        // }

        .img-bitmap,
        .bg-image {
            //order: -1;
            margin-top: 40px;
        }
    }
}

@include stop-at($medium) {
    .section-full--flip--sm {
        display: flex;
        flex-wrap: wrap;

        > *:first-child {
            order: 2;
        }
        > *:last-child {
            order: -1;
        }
    }
}

.section {
    padding: 60px 0;

    @include start-at($medium) {
        padding: 90px 0;
    }
}

.section-x-tall {
    padding: 100px 0;

    @include start-at($medium) {
        padding: 180px 0;
    }
}

// ----------------------------------------
// section
// ----------------------------------------

.info-section {
    background: $gray-dark;
    padding: 60px 0 0;

    .h6 {
        font-size: 11px;
        letter-spacing: 0.5px;
        font-weight: 500;
    }

    .h5 {
        line-height: 1;
    }

    .info-section__cta {
        //height: 348px;
        position: relative;
        z-index: 1;
        padding: 44px 0;
        margin-top: 80px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 90px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: $white;
            z-index: -2;

        }

        .cta {
            margin-top: 0;
        }

        img {
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            height: 100%;
            z-index: -1;
            max-width: none;
        }
    }

    @include start-at($medium) {
        padding: 80px 0 0;

        .info-section__cta {
            height: 458px;
            padding: 64px 0 0 0;
        }
    }

}



// ----------------------------------------
// Padding/ Margin Add-ons
// ----------------------------------------

@mixin spacing($property, $size) {
    #{$property}: $size - 16px;

    @include breakpoint(md) {
        #{$property}: $size;
    }

    @include start-at(1600px) {
        #{$property}: $size * 2;
    }

}

.mt-7 {
	@include spacing(margin-top, 52px);
}
.mb-7 {
	@include spacing(margin-bottom, 52px);
}

.pt-7 {
	@include spacing(padding-top, 70px);
}

.pb-7 {
	@include spacing(padding-bottom, 70px);
}

.mt-8 {
	@include spacing(margin-top, 64px);
}
.mb-8 {
	@include spacing(margin-bottom, 64px);
}

.pt-8 {
	@include spacing(padding-top, 64px);
}

.pb-8 {
	@include spacing(padding-bottom, 64px);
}

.mt-9 {
	@include spacing(margin-top, 96px);
}
.mb-9 {
	@include spacing(margin-bottom, 96px);
}

.pt-9 {
	@include spacing(padding-top, 96px);
}

.pb-9 {
	@include spacing(padding-bottom, 96px);
}

.col {
    width: 100%;
}

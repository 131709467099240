// ---------------------------------------------------------------------
//  vida Styles
// ---------------------------------------------------------------------

$vida: #5079FB;
$vida-yellow: #EBDC18;


#vida {

    .vida-logos {
        position: relative;
        padding: 60px 0;
    }

    .vida-logos-2 {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 90%;
        max-width: 644px;
    }

    .vida-logos-1 {
        position: absolute;
        top: 100%;
        left: 0;
        max-width: 644px;
        width: 90%;
    }

    @mixin selection {
        color: $white;
        background: $vida;
    }

    ::selection {
        @include selection;
    }
    ::-moz-selection {
        @include selection;
    }

    .hero {
        background: $vida;

        .hero__content {
            width: 100%;
            max-width: 1157px;

            @include start-at(1440px) {
                max-width: 1600px;
            }

            p {
                max-width: 1061px;

                + p {
                    margin-top: 0;
                }
            }
        }

        .vida-hero {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
        }
    }

    [data-drawer-target] {
        background: $vida;
    }

    .h1 {
        letter-spacing: 0;
        text-align: left;
        line-height: 1.125;
        font-size: 40px;
    }

    .container-alt {
        max-width: 1157px;

        @include start-at(1440px) {
            max-width: 1600px;
        }
    }
}

.hero--vida {
    align-items: flex-end;
    overflow: hidden;
}


.vida-h-rule {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;

    @include stop-at($medium) {
        right: -25px;
    }
}

.vida-logo {
    width: 515px;
    max-width: 80%;
    margin-bottom: 30px;

    @include start-at($medium) {
        margin-bottom: 45px
    }

    @media (min-height: 820px){
        margin-bottom: 60px;
    }
}


main {
    overflow: hidden;
}

.bg-vida {
    overflow: visible;
    z-index: 2;
    width: 100%;

    .container {
        position: relative;
        z-index: 2;

        @include start-at($medium) {
            > * {
                width: 585px;
            }
        }
    }

    .vida-product {
        max-width: 145px;
        position: absolute;
        right: 7%;
        top: calc(100% - 160px);
        z-index: 1;
        transform-origin: center;
        transform: rotate(-90deg);

        @include start-at($small) {
            max-width: 285px;
            top: calc(100% - 320px);
        }

        @include start-at($medium) {
            transform: rotate(0);
            right: 65%;
            max-width: 465px;
            top: auto;
            bottom: -70px;
        }

        @include start-at($large) {
            left: 7%;
            right: auto;
        }
    }
}


.text-vida {
    color: $vida;
}


// ----------------------------------------
//
// ----------------------------------------

.bg-vida-blue {
    background: $vida;
}
.bg-vida-yellow {
    background: $vida-yellow;
}

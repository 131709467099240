// stylelint-disable block-closing-brace-space-before, primer/selector-no-utility, selector-list-comma-newline-after
// stylelint-disable comment-empty-line-before
// Type scale variables found in primer-support/lib/variables.scss



// Type utils with light weight that match type scale
/* Set the font size to 40px and weight to light */
.f00-light {
  font-size: 40px !important;
  font-weight: $font-weight-light !important;

  @include breakpoint(md) { font-size: $h00-size !important; }

}

/* Set the font size to 32px and weight to light */
.f0-light {
  font-size: 32px !important;
  font-weight: $font-weight-light !important;

  @include breakpoint(md) { font-size: $h0-size !important; }

}

/* Set the font size to 26px and weight to light */
.f1-light {
  font-size: 26px !important;
  font-weight: $font-weight-light !important;

  @include breakpoint(md) { font-size: $h1-size !important; }

}

/* Set the font size to 22px and weight to light */
.f2-light {
  font-size: 22px !important;
  font-weight: $font-weight-light !important;

  @include breakpoint(md) { font-size: $h2-size !important; }

}
// Same size and weight as .lead but without color property
/* Set the font size to 18px and weight to light */
.f3-light {
  font-size: 18px !important;
  font-weight: $font-weight-light !important;

  @include breakpoint(md) { font-size: $h3-size !important; }

}

// Smallest text size
/* Set the font size to ${#h6-size} */
.text-small { font-size: 14px !important; } // 12px

/* Large leading paragraphs */
.lead {
  margin-bottom: 30px;
  font-size: $h3-size;
  font-weight: $font-weight-light;
  color: #555;
}

// Line-height variations
// Close to commonly used line-heights. Most line-heights
// combined with type size equate to whole pixels.
// Will be improved with future typography scale updates.
/* Set the line height to ultra condensed */
.lh-condensed-ultra { line-height: $lh-condensed-ultra !important; }
/* Set the line height to condensed */
.lh-condensed { line-height: $lh-condensed !important; }
/* Set the line height to default */
.lh-default { line-height: $lh-default !important; }

// Text alignments
/* Text align to the right */
.text-right { text-align: right !important; }
/* Text align to the left */
.text-left { text-align: left !important; }
/* Text align to the center */
.text-center { text-align: center !important; }

// Responsive text alignment
// .text-md-left, .text-lg-right, ...
@each $breakpoint in map-keys($breakpoints) {
  @include breakpoint($breakpoint) {
    .text-#{$breakpoint}-right { text-align: right !important; }
    .text-#{$breakpoint}-left { text-align: left !important; }
    .text-#{$breakpoint}-center { text-align: center !important; }
  }
}


// List styles
.list-style-none { list-style: none !important; }

// Text Shadows
/* Add a dark text shadow */
.text-shadow-dark {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 1px 25px rgba(0, 0, 0, 0.75);
}
/* Add a light text shadow */
.text-shadow-light {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
